import React, { useState } from "react";
import { Badge, Button, Descriptions, message } from "antd";
import { TimelineView } from "../Timeline/Timeline";
import { ProfilePLayer } from "../profile-player";
import { getAthleteById } from "../../servicers/api-athlete";
import { getTeamById } from "../../servicers/api-team";

export const InfoPlayer = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const data = { ...props.data };
  const [moreData, setMoreData] = useState({});
  // show profile
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const moreProfile = async () => {
    const res = await getAthleteById(data.id);
    if (res?.data?.EC == 0) {
      const getDataTeam = await getTeamById(res?.data?.DT?.team);
      if (getDataTeam?.data?.EC == 0) {
        setMoreData({ ...res?.data?.DT, team: getDataTeam?.data?.DT });
        props?.close(false);
        showDrawer();
      }


    } else {
      messageApi.open({
        type: "error",
        content: res?.data?.EM,
      });
    }
  };

  const items = [
    {
      key: "1",
      label: "ID",
      children: data?.id,
    },
    {
      key: "2",
      label: "Age",
      children: data?.age,
    },
    {
      key: "3",
      label: "Ranking",
      children: data?.rank,
    },
    {
      key: "4",
      label: "Name",
      children: data?.name,
    },
    {
      key: "5",
      label: "Gender",
      children: data?.gender,
    },

    {
      key: "6",
      label: "Category",
      children: data?.category,
    },
    {
      key: "7",
      label: "Point",
      children: data?.point,
    },

    {
      key: "8",
      label: "Team",
      children: data?.team,
      span: 2,
    },
    {
      key: "9",
      label: "Status",
      children: <Badge status="processing" text="Running" />,
      span: 3,
    },
    {
      key: "10",
      label: "Achievement",
      span: 3,
      children: (
        <>
          <TimelineView data={data?.achievements} />
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Descriptions labelStyle={{ fontWeight: 900 }} bordered items={items} />
      <ProfilePLayer data={moreData} open={open} onClose={onClose} />
      <Button
        style={{
          backgroundColor: "#000",
          padding: "16px 0",
          margin: "12px 0px",
          width: "100%",
        }}
        onClick={moreProfile}
        type="primary"
      >
        More Infomation
      </Button>
    </>
  );
};
