export const RankByAge = [
  {
    key: "1",
    label: "< 5",
  },
  {
    key: "2",
    label: "6-7",
  },
  {
    key: "3",
    label: "8-9",
  },
  {
    key: "4",
    label: "10-11",
  },
  {
    key: "5",
    label: "12-13",
  },
  {
    key: "6",
    label: ">13",
  },
];
