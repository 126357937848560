// --> banner <---
export const defaultSlider = [
  {
    id: 1,
    link: "https://www.worldskate.org/templates/ifrit/images/testata-rollerFree.jpg",
    // link :"https://static.wixstatic.com/media/267ff0_a1bc35e552e4444987eaf581e505342a~mv2.jpg/v1/fill/w_2017,h_1069,al_c,q_90,enc_auto/267ff0_a1bc35e552e4444987eaf581e505342a~mv2.jpg"

  },
  {
    id: 2,
    link: "https://www.worldskate.org/templates/ifrit/images/testata-skate.jpg",
  },
  {
    id: 3,
    link: "https://www.worldskate.org/templates/ifrit/images/testata-freestyle.jpg",
  },
  {
    id: 4,
    link: "https://www.worldskate.org/templates/ifrit/images/inline-hockey.jpg",
    
  },
];


