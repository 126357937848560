import React, { useEffect, useState } from "react";
import { Space, Table, Tag } from "antd";
import { CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import { fakeData } from "../../Data/fake-data-ranking";
import { ProfilePLayer } from "../profile-player";

import { CmsSearchModel, CmsSearchPlayer } from "../cms-model-search";

import { ActivityIndicator } from "../ActivityIndicator";
import { getAllTeam } from "../../servicers/api-team";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "createdAt",
    dataIndex: "createdAt",
    key: "createdAt",
  },
];

export const CmsTableTeam = () => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [list, setList] = useState([]);
  const [dataModal, setDataModal] = useState({});
  const [keyWordSearch, setkeyWordSearch] = useState("");
  const [loading , setLoading] = useState(true)
  const onClose = () => {
    setOpen(false);
  };

  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const getDataPlayer = async () => {
    const res = await getAllTeam();
    if (res?.data && res?.data.EC == 0) {
      setList(res?.data?.DT);
      setLoading(false)
    }
    // setList(fakeData);
  };
console.log("check list", list);
  useEffect(() => {
    getDataPlayer();
  }, []);

  const handleRowClick = (record) => {
    setDataModal({ ...record });
    showLargeDrawer();
  };

  const handleSearch = () => {
    const _list = [...list]
    const filter = _list.filter((item) => item.id == keyWordSearch)
    if(filter && filter.length > 0 && keyWordSearch !== "" ) {
      setDataModal({ ...filter[0] });
    //   showLargeDrawer();
      setkeyWordSearch("");
      return;
    } 
 

  };

  return (
    <>
      <CmsSearchModel
        keyWordSearch={keyWordSearch}
        setkeyWordSearch={setkeyWordSearch}
        handleSearch={handleSearch}
      />
      <div style={{ background: "#fff" }} className="container-home">
        {loading && (
          <div style={{ height: "100vh", marginTop: 100 }}>
            <ActivityIndicator />
          </div>
        )}

        {loading == false && (
          <Table
            style={{ cursor: "pointer" }}
            dataSource={list}
            responsive="true"
            size="small"
            columns={columns}
          />
        )}
      </div>
    </>
  );
};
