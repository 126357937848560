import { Button, Table, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as XLSX from "xlsx";
import { createTeam } from "../../servicers/api-team";
import { uploadImg } from "../../servicers/api-upload";
import { ActivityIndicator } from "../ActivityIndicator";
import { createImageUrl } from "../../servicers/api-get-img";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Team",
    dataIndex: "team",
    key: "team",
  },
];

export const CmsCreateTeam = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [logoTeam, setLogoTeam] = useState(null);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [showImg ,setShowImg] = useState(false)

  const [excelData, setExcelData] = useState(null);

  const handleFileUpload = (info) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(jsonData);
    };
    reader.readAsArrayBuffer(info);
    // }
  };
  const resetFields = () => {
    setLogoTeam(null)
    setId("")
    setName("")
  }

  console.log("check list", excelData);

  const handleChangeAvatar = async (e) => {
    setShowImg(true)
    const file = e.target.files[0];
    // setAvatar(file);
    // const req = await uploadImg(file);
    // console.log("check req" ,req)

    const formData = new FormData();
    formData.append("file", file);
    const response = await uploadImg(formData);
    if (response?.data?.EC == 0) {
      console.log("check img upload ", response?.data?.DT);
      setLogoTeam(response?.data?.DT);
      messageApi.open({
        type: "success",
        content: `${response?.data?.EM}`,
      });
    setShowImg(false)

    } else {
      messageApi.open({
        type: "error",
        content: `${response?.data?.EM}`,
      });
    }
  };

  const handleSubmit = async () => {
    if (name.length <= 0 || id.name <= 0) return;
    const payload = {
      id: id,
      name: name,
      logo : logoTeam || null
      // logo: "default",
    };
    const req = await createTeam(payload);
    if (req?.data?.EC == 0) {
      resetFields()
      messageApi.open({
        type: "success",
        content: req?.data?.EM,
      });
    } else {
      messageApi.open({
        type: "error",
        content: req?.data?.EM,
      });
    }

    console.log("check payload team", req);
  };

  return (
    <>
      {contextHolder}
      <div className="container-create-player">
        <div className="form-create-player">
          <label className="label-creater-player">ID</label>
          <input
            disabled={props?.typeModel == "update" ? true : false}
            value={id}
            onChange={(e) => {
              setId(e.target.value);
              console.log("check date", e.target.value);
            }}
            className="input-create-player"
            placeholder="ID"
          />
        </div>

        <div className="form-create-player">
          <label className="label-creater-player">Tên câu lạc bộ</label>
          <input
            disabled={props?.typeModel == "update" ? true : false}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              console.log("check date", e.target.value);
            }}
            className="input-create-player"
            placeholder="Tên câu lạc bộ"
          />
        </div>

        <div className="form-create-player"></div>
        <div className="form-create-player"></div>

        <div
          style={{ width: "100%", display: "flex" }}
          className="form-create-player"
        >
          <label className="label-creater-player">Ảnh câu lạc bộ</label>
          <input
            onChange={handleChangeAvatar}
            type="file"
            name="avatar"
            accept="image/*"
          ></input>
          {showImg == true && (
            <div
              style={{
                width: 200,
                height: 200,
                alignItems: "center",
                display: "flex",
                justifyItems: "center",
              }}
            >
              <ActivityIndicator />
            </div>
          )}
          {logoTeam != null && (
            <img
              style={{ width: 200, height: 200 }}
              src={createImageUrl(logoTeam)}
              crossorigin="anonymous"
            />
          )}
          {/* <Upload onChange={handleChangeAvatar}>
          <Button style={{ height: 40 }} icon={<UploadOutlined />}>
            Click to Upload
          </Button>
        </Upload> */}
          {/* <UploadImg fileList={fileList} setFileList={setFileList} /> */}
        </div>

        <div className="form-create-player">
          <label className="label-creater-player">Danh sách thành viên</label>
          <Upload
            beforeUpload={handleFileUpload}
            accept=".xlsx, .xls"
            style={{ width: "100%" }}
          >
            <Button style={{ height: 40 }} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </div>
      </div>
      {excelData && excelData.length > 0 && (
        <Table
          style={{ cursor: "pointer" }}
          dataSource={excelData}
          responsive="true"
          size="small"
          columns={columns}
        />
      )}

      <div style={{ width: "100%", marginTop: 30 }}>
        <button onClick={handleSubmit} className="btn-submit-creater-player">
          Đăng kí
        </button>
      </div>
    </>
  );
};
