import axios from "axios";
import { BASE_URL } from "../servicers/api-config";

const getAllTeam = async () => {
  const data = await axios.get(`${BASE_URL}/get-all-team`);
  return data;
};


const createTeam = async (rawData) => {
  const data = await axios.post(`${BASE_URL}/create-team`, { ...rawData });
  return data;
};

const getTeamById = async (uid) => {
  const data = await axios.post(`${BASE_URL}/get-team-id`, { id : uid });
  return data;
};

export { createTeam , getAllTeam , getTeamById };
