import { useParams } from "react-router-dom";
import "./Ranking.css";
import { RankingDetail } from "../RankingDetail/RankingDetail";
import { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { LoginFrom } from "../login-form";
import { RankByAge } from "../../Data/default-ranking-tab";
import { useDispatch, useSelector } from "react-redux";
import { setListRanking } from "../../Redux/data-ranking-reducer";
import { ButtonCustom } from "../Button/Button";
import { ActivityIndicator } from "../ActivityIndicator";
import {
  getAllAthlete,
  getAthleteByCategory,
} from "../../servicers/api-athlete";
import { getTeamById } from "../../servicers/api-team";

export const Ranking = () => {
  const dispatch = useDispatch();
  const listRankingStore = useSelector((state) => state.dataRanking.list);
  const [checkedList, setCheckedList] = useState([]);
  const [list, setList] = useState([]);
  const { slug, id } = useParams();
  const [loading, setLoading] = useState(true);
  const containeRef = useRef(null);

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const filterData = async (res, slug) => {
    // const filter = res.filter((item) => {
    //   if (Array.isArray(item.category)) {
    //     return item.category.some(
    //       (categoryItem) =>
    //         convertSlug(categoryItem?.category?.toLowerCase()) == convertSlug(slug)
    //     );
    //   }
    //   return false;
    // });
    const renderList = await Promise.all(
      res.map(async (item) => {
        const point = item?.category?.filter(
          (item) => item.category.toLowerCase() == convertSlug(slug)
        );
        const team = await getTeamById(item?.team);
        return {
          ...item,
          age: String(calculateAge(item?.age)),
          point: point[0]?.point,
          category: convertSlug(slug),
          team: team?.data?.DT?.name,
          logoTeam: team?.data?.DT?.logoTeam,
        };
      })
    );

    // const sortRenderList = renderList.sort(
    //   (a, b) => Number(b.point) - Number(a.point)
    // );
    // if (sortRenderList == null || sortRenderList.length == 0) {
    //   setLoading(true);
    // } else {
    //   setLoading(false);
    // }

    return renderList;
  };

  const convertSlug = (slug) => {
    let result = slug.replace(/-/g, " ");
    const normalizedSlug = result.toLowerCase();
    // console.log("check", normalizedSlug)
    return normalizedSlug;
  };

  const sortArray = (arr) => {
    return arr.sort((a, b) => Number(b.point) - Number(a.point));
  };

  const getData = async () => {
    setLoading(true);
    const data = await getAthleteByCategory(convertSlug(slug));
    if (data?.data?.EC == 0) {
      const _data = await filterData(data?.data?.DT, slug);
      setLoading(false);
      if (id == 1) {
        const filterByGender = _data.filter((item) => item.gender == "Men");
        if (checkedList == "1") {
          const filterByAge = filterByGender.filter(
            (item) => Number(item.age) <= 5
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "2") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "6" && item.age <= "7"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "3") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "8" && item.age <= "9"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "4") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "10" && item.age <= "11"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "5") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "12" && item.age <= "13"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "6") {
          const filterByAge = filterByGender.filter(
            (item) => Number(item.age) >= 13
          );
          setList(sortArray(filterByAge));
        }
      }
      if (id == 2) {
        const filterByGender = _data.filter((item) => item.gender == "Women");
        if (checkedList == "1") {
          const filterByAge = filterByGender.filter(
            (item) => Number(item.age) <= 5
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "2") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "6" && item.age <= "7"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "3") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "8" && item.age <= "9"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "4") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "10" && item.age <= "11"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "5") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "12" && item.age <= "13"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "6") {
          const filterByAge = filterByGender.filter(
            (item) => Number(item.age) >= 13
          );
          setList(sortArray(filterByAge));
        }
      }
      if (id == 3) {
        const filterByGender = _data.filter((item) => item.gender == "Men");
        if (checkedList == "1") {
          const filterByAge = filterByGender.filter(
            (item) => Number(item.age) <= 5
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "2") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "6" && item.age <= "7"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "3") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "8" && item.age <= "9"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "4") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "10" && item.age <= "11"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "5") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "12" && item.age <= "13"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "6") {
          const filterByAge = filterByGender.filter(
            (item) => Number(item.age) >= 13
          );
          setList(sortArray(filterByAge));
        }
      }
      if (id == 4) {
        const filterByGender = _data.filter((item) => item.gender == "Women");
        if (checkedList == "1") {
          const filterByAge = filterByGender.filter(
            (item) => item.age > "0" && item.age <= "5"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "2") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "6" && item.age <= "7"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "3") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "8" && item.age <= "9"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "4") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "10" && item.age <= "11"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "5") {
          const filterByAge = filterByGender.filter(
            (item) => item.age >= "12" && item.age <= "13"
          );
          setList(sortArray(filterByAge));
        }
        if (checkedList == "6") {
          const filterByAge = filterByGender.filter((item) => item.age >= "13");
          setList(sortArray(filterByAge));
        }
      }
      if (id == 5 || id == 7 || id == 9 || id == 12 || id == 13) {
        const filterByGender = _data.filter((item) => item.gender == "Men");
        setList(sortArray(filterByGender));
      }
      if (id == 6 || id == 8 || id == 10 || id == 11 || id == 14) {
        const filterByGender = _data.filter((item) => item.gender == "Women");
        setList(sortArray(filterByGender));
      }
    } else {
      setLoading(true);
    }
  };

  const scrollToTableRanking = () => {
    const element = containeRef.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    getData();
    scrollToTableRanking();
  }, [id, checkedList]);

  const [modalLogin, setModalLogin] = useState(false);

  const onChange = (key) => {
    console.log("check age", key);
    setCheckedList([...key]);
  };

  useEffect(() => {
    onChange("1");
  }, []);

  return (
    <div className="container-ranking">
      <div className="header-ranking">
        <div className="title-ranking">
          <h3 style={{ textAlign: "start" }}>
            RANKING {slug.toUpperCase().replace(/-/g, " ")}
          </h3>
        </div>

        {(id == 1 || id == 2 || id == 3 || id == 4) && (
          <div className="choose-age">
            {RankByAge.map((item, index) => {
              return (
                <ButtonCustom
                  key={index}
                  label={item?.label}
                  onChange={onChange}
                  value={item?.key}
                  active={checkedList}
                />
              );
            })}
          </div>
        )}
      </div>
      <div ref={containeRef}></div>
      {loading && (
        <div style={{ height: "100vh", marginTop: 100 }}>
          <ActivityIndicator />
        </div>
      )}

      {loading == false && (
        <>
          {id == 1 || id == 2 || id == 3 || id == 4 ? (
            <>
              {checkedList &&
                checkedList.map((item, index) => {
                  return (
                    <div key={index}>
                      <RankingDetail list={list} />
                    </div>
                  );
                })}
            </>
          ) : (
            <RankingDetail list={list} />
          )}
        </>
      )}

      <Modal
        centered
        open={modalLogin}
        onCancel={() => setModalLogin(false)}
        footer={null}
        width="50%"
        style={{ textAlign: "center" }}
        className="custom-modal"
      >
        <LoginFrom />
      </Modal>
      <button onClick={() => setModalLogin(true)} className="btn-login">
        {/* <UserOutlined /> */}
        <a style={{ margin: "0 5px", color: "#fff" }}>Sign in</a>
      </button>
    </div>
  );
};
