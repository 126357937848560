import React, { useEffect, useState } from "react";
import "./Home.css";
import { HeaderHome } from "../HeaderHome";
import { HomeContent } from "../HomeContent";
import { HomeSearch } from "../home-search";
import { useDispatch, useSelector } from "react-redux";
import { setListRanking } from "../../Redux/data-ranking-reducer";
import { fakeData } from "../../Data/fake-data-ranking";
import { HomeTableRanking } from "../home-table-ranking";
import { HomeAbout } from "../home-about";
import { message } from "antd";
import { getAllAthlete } from "../../servicers/api-athlete";
import { ActivityIndicator } from "../ActivityIndicator";

export const Home = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  console.log("check store", loading);

  const getData = async () => {
    const response = await getAllAthlete();
    if (response?.data?.EC == 0) {
      dispatch(setListRanking(response?.data?.DT));
      // setLoading(false)
    } else {
      messageApi.open({
        type: "error",
        content: response?.data?.EM,
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="home-container">
        {contextHolder}
        <HeaderHome />
        <div style={{ marginTop: "0rem" }}>
          <HomeAbout />
          <HomeContent />
          <HomeSearch />
          <HomeTableRanking />
          <div style={{ height: 500 }}></div>
        </div>
      </div>
    </>
  );
};
