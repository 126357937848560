import axios from "axios";
import {BASE_URL} from "../servicers/api-config"

const registerAPI = async (data) => {
  const check = await axios.post(`${BASE_URL}/register`, { ...data });
  return check;
};

const loginAPI = async (data) => {
  const check = await axios.post(`${BASE_URL}/login`, { ...data });
  return check;
};

const getDataUser = async () => {
  const data = await axios.get(`${BASE_URL}/get-data-user`)
  return data
}




export { loginAPI, registerAPI , getDataUser };