import React, { useState, useEffect } from "react";
import { Button } from "antd";

const LoadingImage = ({ src, alt, style  }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoading(false);
    img.onerror = () => setLoading(false); // Xử lý lỗi nếu cần
  }, [src]);

  return (
    <div style={{ ...style }}>
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button style={{ border: "none" , backgroundColor: "transparent",}} color="#fff" loading></Button>
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{
          ...style,
          opacity: loading ? 0 : 1,
          transition: "opacity 0.5s ease",
        }}
        crossorigin="anonymous"
        loading="lazy"
      />
    </div>
  );
};

export default LoadingImage;
