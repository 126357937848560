import "./styles.css"

export const CmsSearchModel = (props) => {
    const {keyWordSearch , setkeyWordSearch , handleSearch} = props || null
    return (
        <div className="cms-search-player">
            <div className="cms-search-player-border">
            <input
             className="cms-search-player-input" 
             placeholder="ID"
             value={keyWordSearch}
             onChange={(e)=>setkeyWordSearch(e.target.value)}
             />
            <button onClick={handleSearch}>Search</button>

            </div>
        </div>
    )
}