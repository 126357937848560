import "./Footer.css"

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <h3 style={{color : "#fff"}} >© Viet Nam Roller Team JSC</h3>
      </div>
    </>
  );
};
