import React, { useState, useMemo, useEffect, useCallback } from "react";
import "./styles.css";
import { defaultMenuBottom } from "../../Data/default-menu";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Upload, Select, message } from "antd";
import { UploadImg } from "../cms-upload-image";
import { createAthlete, updateAthlete } from "../../servicers/api-athlete";
import { getAllTeam } from "../../servicers/api-team";
import { defaultPoint } from "../../Data/default-point";
import barem from "../../Image/barem.jpg";
import { uploadImg } from "../../servicers/api-upload";
import axios from "axios";
import { createImageUrl } from "../../servicers/api-get-img";
import { ActivityIndicator } from "../ActivityIndicator";

const { Option } = Select;

export const CreatePlayer = (props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [showCategory, setShowCategory] = useState(false);
  const [showAchievements, setShowAchievements] = useState(false);
  const [showBtnSubmit, setShowBTnSubmit] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [selectTeam, setSelectTeam] = useState("");
  const [selectGender, setSelectGender] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [categories, setCategories] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [team, setTeam] = useState([]);

  const [incrementPoint, setIncrementPoint] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [showImg, setShowImg] = useState(false);

  const defautData = () => {
    setId(props?.data?.id);
    setName(props?.data?.name);
    setAge(props?.data?.age);
    setSelectGender(props?.data?.gender);
    setSelectTeam(props?.data?.team);
    setAvatar(props?.data?.avatar);
    if (props?.data?.category.length > 0) {
      setShowCategory(true);
      setCategories([...props?.data?.category]);
    }
    if (props?.data?.achievements.length > 0) {
      setShowAchievements(true);
      setAchievements(props?.data?.achievements);
    }
  };

  useEffect(() => {
    defautData();
  }, [props?.data?.id , props?.open]);

  useEffect(() => {
    getDataTeam();
  }, []);

  const getDataTeam = async () => {
    const res = await getAllTeam();
    if (res && res?.data?.EC == 0) setTeam(res?.data?.DT);
  };

  const handleSubmit = async () => {
    const data = {
      id: id,
      username: name,
      team: selectTeam,
      age: age,
      gender: selectGender,
      avatar: avatar,
      // avatar: null,
      category: categories,
      achievements: achievements,
    };
    console.log("check post api", data);
    if (props?.typeModel !== "update") {
      const req = await createAthlete(data);
      if (req?.data?.EC == 0) {
        resetForm();
        getDataTeam();
        messageApi.open({
          type: "success",
          content: `${req?.data?.EM}`,
        });
      } else {
        messageApi.open({
          type: "error",
          content: `${req?.data?.EM}`,
        });
      }
      return;
    }
    if (props?.typeModel == "update") {
      const req = await updateAthlete(data);
      if (req?.data?.EC == 0) {
        resetForm();
        getDataTeam();
        messageApi.open({
          type: "success",
          content: `${req?.data?.EM}`,
        });
        props?.onClose();
      } else {
        messageApi.open({
          type: "error",
          content: `${req?.data?.EM}`,
        });
      }
      return;
    }
  };

  useEffect(() => {
    const data = {
      id: id,
      username: name,
      team: selectTeam,
      age: age,
      gender: selectGender,
      avatar: avatar,
      category: categories,
      achievements: achievements,
    };

    for (let e in data) {
      if (data[e] == null || data[e].length <= 0 || data[e] == []) {
        if (e[6] == null || e[7] == null) continue;
        setShowBTnSubmit(false);
      } else {
        setShowBTnSubmit(true);
      }
    }
  }, [
    id,
    name,
    selectTeam,
    age,
    selectGender,
    avatar,
    categories,
    achievements,
  ]);

  const handleAddNewCategory = () => {
    setShowCategory(true);
    const newCategories = [...categories];
    newCategories.push({ category: "", point: "" });
    setCategories(newCategories);
  };

  const handleCategoryChange = (value, index) => {
    const newCategories = [...categories];
    newCategories[index].category = value;
    setCategories(newCategories);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handlePointChange = useCallback(
    debounce((value, index) => {
      const newCategories = [...categories];
      if (props?.typeModel === "update") {
        newCategories[index].point = String(
          Number(newCategories[index].point) + Number(value)
        );
      } else {
        newCategories[index].point = value;
      }
      setCategories(newCategories);
      console.log("check data", newCategories[index]);
    }, 500), // Thời gian debounce (500ms)
    [categories, props]
  );

  // const handlePointChangeCreate = (value, index) => {
  //   const newCategories = [...categories];
  //     newCategories[index].point = value;
  //   setCategories(newCategories);
  //   console.log("check data", newCategories[index]);
  // };

  const onChangeHandler = (e, index) => {
    handlePointChange(e, index);
  };

  const removeCategory = (item) => {
    const remove = categories.filter((categories) => categories !== item);
    setCategories(remove);
    console.log("check remove", remove);
  };

  const handleAddNewAchievements = () => {
    setShowAchievements(true);
    const newAchievements = [...achievements];
    newAchievements.push({ achievements: "", date: "" });
    setAchievements(newAchievements);
  };

  const handleDateAchievementsChange = (value, index) => {
    const newAchievements = [...achievements];
    newAchievements[index].date = value;
    setAchievements(newAchievements);
  };

  const handleInputAchievementsChange = (value, index) => {
    const newAchievements = [...achievements];
    newAchievements[index].achievements = value;
    setAchievements(newAchievements);
  };

  const removeAchievements = (item) => {
    const remove = achievements.filter((achievements) => achievements !== item);
    setAchievements(remove);
    console.log("check remove", remove);
  };

  const handleChangeAvatar = async (e) => {
    setShowImg(true);
    const file = e.target.files[0];
    // setAvatar(file);
    // const req = await uploadImg(file);
    // console.log("check req" ,req)

    const formData = new FormData();
    formData.append("file", file);
    const response = await uploadImg(formData);
    if (response?.data?.EC == 0) {
      console.log("check img upload ", response?.data?.DT);
      setAvatar(response?.data?.DT);
      messageApi.open({
        type: "success",
        content: `${response?.data?.EM}`,
      });
      setShowImg(false);
    } else {
      messageApi.open({
        type: "error",
        content: `${response?.data?.EM}`,
      });
    }
  };

  const resetForm = () => {
    setId("");
    setName("");
    setAge("");
    setSelectTeam("");
    setSelectGender("");
    setAvatar(null);
    setCategories([]);
    setAchievements([]);
    setTeam([]);
  };

  return (
    <div className="container-create-player">
      {contextHolder}
      <div className="form-create-player">
        <label className="label-creater-player">ID</label>
        <input
          value={id}
          onChange={(e) => setId(e.target.value)}
          className="input-create-player"
          placeholder="ID"
          disabled={props?.typeModel == "update" ? true : false}
        />
      </div>

      <div className="form-create-player">
        <label className="label-creater-player">Họ và tên</label>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input-create-player"
          placeholder="Họ và tên"
          // disabled={props?.typeModel == "update" ? true : false}
        />
      </div>

      <div className="form-create-player">
        <label className="label-creater-player">Giới tính</label>
        <Select
          value={selectGender}
          disabled={props?.typeModel == "update" ? true : false}
          onChange={(value) => setSelectGender(value)}
          placeholder="Vui lòng chọn giới tính"
          style={{
            height: 40,
          }}
        >
          <Option value="Men">Nam</Option>
          <Option value="Women">Nữ</Option>
        </Select>
      </div>

      <div className="form-create-player">
        <label className="label-creater-player">Tuổi</label>
        <input
          // disabled={props?.typeModel == "update" ? true : false}
          value={age}
          onChange={(e) => {
            setAge(e.target.value);
            console.log("check date", e.target.value);
          }}
          className="input-create-player"
          type="date"
          placeholder="Tuổi"
        />
      </div>

      <div className="form-create-player">
        <label className="label-creater-player">Câu lạc bộ</label>
        <Select
          value={selectTeam}
          onChange={(value) => setSelectTeam(value)}
          placeholder="Vui lòng chọn câu lạc bộ"
          style={{
            height: 40,
          }}
        >
          {team.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>

      <div
        style={{ width: "100%", display: "flex" }}
        className="form-create-player"
      >
        <label className="label-creater-player">Ảnh đại diện</label>
        <input
          style={{ marginLeft: 6 }}
          onChange={handleChangeAvatar}
          type="file"
          name="avatar"
          accept="image/*"
        ></input>
        {showImg == true && (
          <div
            style={{
              width: 200,
              height: 200,
              alignItems: "center",
              display: "flex",
              justifyItems: "center",
            }}
          >
            <ActivityIndicator />
          </div>
        )}
        {avatar != null && (
          <img
            style={{ width: 200, height: 200 }}
            src={createImageUrl(avatar)}
            crossorigin="anonymous"
          />
        )}
        {/* <Upload onChange={handleChangeAvatar}>
          <Button style={{ height: 40 }} icon={<UploadOutlined />}>
            Click to Upload
          </Button>
        </Upload> */}
        {/* <UploadImg fileList={fileList} setFileList={setFileList} /> */}
      </div>

      <div className="form-create-player"></div>
      <div className="form-create-player"></div>
      <div className="form-create-player"></div>
      <div className="form-create-player"></div>

      <div style={{ width: "100%" }} className="form-create-player-category">
        <label style={{ fontWeight: 700 }} className="label-creater-player">
          Hạng mục và điểm
        </label>
        <button
          onClick={handleAddNewCategory}
          style={{ width: 100, marginLeft: 6 }}
        >
          + Thêm mới
        </button>
        {showCategory && (
          <>
            {categories.map((item, index) => (
              <div
                key={index}
                style={{ gap: "10px", display: "flex", marginTop: "10px" }}
              >
                <Select
                  value={item.category}
                  onChange={(value) => handleCategoryChange(value, index)}
                  placeholder="Vui lòng chọn hạng mục"
                  style={{
                    height: 40,
                    width: "50%",
                  }}
                >
                  {defaultMenuBottom.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                {props?.typeModel == "update" && (
                  <input
                    value={`Điểm số hiện tại là : ${item.point}`}
                    className="input-create-player"
                    disabled={true}
                  />
                )}

                {props?.typeModel == "update" && (
                  <input
                    onChange={(e) => {
                      onChangeHandler(e.target.value, index);
                    }}
                    placeholder="Nhập điểm cộng thêm"
                    className="input-create-player"
                  />
                )}

                {props?.typeModel !== "update" && (
                  <input
                    // value={item.point}
                    onChange={(e) => onChangeHandler(e.target.value, index)}
                    placeholder="Vui lòng nhập điểm số"
                    className="input-create-player"
                  />
                )}

                <button
                  style={{
                    padding: 12,
                    border: "none",
                    backgroundColor: "#fff",
                    color: "#000",
                  }}
                  onClick={() => removeCategory(item)}
                >
                  X
                </button>
              </div>
            ))}
          </>
        )}
      </div>

      <div style={{ width: "70%" }} className="form-create-player-category">
        <label style={{ fontWeight: 700 }} className="label-creater-player">
          Thành tựu
        </label>
        <button
          onClick={handleAddNewAchievements}
          style={{ width: 100, marginLeft: 6 }}
        >
          + Thêm mới
        </button>
        {showAchievements && (
          <>
            {achievements.map((item, index) => (
              <div
                key={index}
                style={{ gap: "10px", display: "flex", marginTop: "10px" }}
              >
                <input
                  onChange={(e) =>
                    handleDateAchievementsChange(e.target.value, index)
                  }
                  value={item?.date}
                  style={{ width: "30%" }}
                  className="input-create-player"
                  type="date"
                />
                <input
                  className="input-create-player"
                  style={{ width: "70%" }}
                  placeholder="Thành tựu"
                  value={item?.achievements}
                  onChange={(e) =>
                    handleInputAchievementsChange(e.target.value, index)
                  }
                />

                <button
                  style={{
                    padding: 12,
                    border: "none",
                    backgroundColor: "#fff",
                    color: "#000",
                  }}
                  onClick={() => removeAchievements(item)}
                >
                  X
                </button>
              </div>
            ))}
          </>
        )}
      </div>

      {showBtnSubmit && (
        <div style={{ width: "100%", textAlign: "center", marginTop: 30 }}>
          <button onClick={handleSubmit} className="btn-submit-creater-player">
            Lưu vận động viên
          </button>
        </div>
      )}

      <img
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          borderTop: "1px solid #000",
          borderBottom: "1px solid #000",
        }}
        src={barem}
      />
    </div>
  );
};
