import axios from "axios";
import {BASE_URL} from "../servicers/api-config"


const getAllAthlete = async () => {
  const data = await axios.get(`${BASE_URL}/athlete`);
  return data;
};

const createAthlete = async (rawData) => {
  const data = await axios.post(`${BASE_URL}/create-athlete`, { ...rawData });
  return data;
};

const updateAthlete = async (rawData) => {
  const data = await axios.post(`${BASE_URL}/update-athlete`, { ...rawData });
  return data;
};

const deleteAthlete = async (uid) => {
  const data = await axios.post(`${BASE_URL}/delete-athlete`, {  id : uid });
  return data;
};

const getAthleteById = async (uid) => {
  const data = await axios.post(`${BASE_URL}/get-athlete-id`, {  id : uid });
  return data;
};

const getTopAthlete = async (amount) => {
  const data = await axios.post(`${BASE_URL}/get-top-athlete`, {  limit : amount });
  return data;
};

const getAthleteByCategory = async (key) => {
  const data = await axios.post(`${BASE_URL}/get-athlete-by-category`, {
    category: key,
  });
  return data;
};

export {
  getAllAthlete,
  createAthlete,
  getAthleteById,
  updateAthlete,
  deleteAthlete,
  getTopAthlete,
  getAthleteByCategory,
};