import { useLocation } from "react-router-dom";
import "./styles.css";
import { Col, Empty, Row } from "antd";
import { RankingDetail } from "../RankingDetail/RankingDetail";
import { fakeData } from "../../Data/fake-data-ranking";
import { useEffect, useRef, useState } from "react";
import { getAllAthlete } from "../../servicers/api-athlete";
import { createImageUrl } from "../../servicers/api-get-img";
import logo from "../../Image/headerContent.png";

export const ProfileTeam = () => {
  const { state } = useLocation();
  const [list, setList] = useState([]);

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const filterData = (res) => {
    const filtered = res.filter((item) => item?.team === state?.id);

    const transformed = filtered.map((item) => ({
      ...item,
      age: String(calculateAge(item?.age)),
      point: item?.category?.map((cat) => cat?.point).join(", "),
      category: item?.category?.map((cat) => cat?.category).join(", "),
    }));
    return transformed;
  };

  const getData = async () => {
    const data = await getAllAthlete();
    if (data?.data?.EC == 0) {
      const _data = await filterData(data?.data?.DT);
      console.log("check data", _data);
      setList(_data);
    } else {
      // setLoading(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(("state", state));
  if (state == null)
    return (
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 500,
        }}
      >
        <Empty />
      </h1>
    );
  return (
    <>
      <div className="container-profile-team">
        <div className="info-team">
          <Row gutter={24}>
            <Col xs={24} sm={12} md={10} lg={5}>
              <div className="logo-profile-team">
                <img
                  crossorigin="anonymous"
                  style={{ borderRadius: 15, width: 250 }}
                  src={createImageUrl(state?.logoTeam)}
                  alt="Logo"
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={14} lg={19}>
              <div className="information-profile-team">
                <span className="text-profile-team gradient-text">
                  ID : {state?.id}
                </span>
                <span className="text-profile-team">Team: {state?.name}</span>
                <span className="text-profile-team">
                  Date: {state?.createdAt?.slice(0, 10)}
                </span>
                <span className="text-profile-team">
                  Update: {state?.updatedAt?.slice(0, 10)}
                </span>
                <span className="text-profile-team">
                  Members: {list.length}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <h3>LIST OF PLAYER ON YOUR TEAM</h3>
        <RankingDetail type={"listTeam"} list={list} />
      </div>
    </>
  );
};
