import React, { useState } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { setLogin } from "../../Redux/login-reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../../servicers/api-user";
import logo from "../../Image/logoteam.png";
import "./styles.css";
import { ActivityIndicator } from "../ActivityIndicator";

export const LoginFrom = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispath = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      username: values.username,
      password: values.password,
    };
    const req = await loginAPI(data);
    if (req?.data && req?.data?.EC == 0) {
      console.log("check", req?.data?.EC);
      const payload = {
        username: req?.data?.DT?.username,
        token: req?.data?.DT?.token,
        isAuthentication: true,
      };
      setLoading(false);
      dispath(setLogin(payload));
      navigate("/");
    } else {
      messageApi.open({
        type: "error",
        content: req?.data?.EM,
      });
      setLoading(false);
    }

    // const payload = {
    //   username: "req?.data?.DT?.username",
    //   token: "req?.data?.DT?.token",
    //   isAuthentication: true,
    // };
    // dispath(setLogin(payload));
    // navigate("/");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {contextHolder}
      {loading && (
        <div className="container-login-form">
          <ActivityIndicator />
        </div>
      )}
      {!loading && (
        <div className="container-login-form">
          <div className="title-login-form">
            <img className="img-login-form" src={logo} />
            <p className="name-login-form">Sign in to access the admin page</p>
          </div>
          <div className="group-login-form">
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="btn-login-form">
                <button type="primary" htmlType="submit">
                  Sign in
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};
