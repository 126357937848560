import logo from "../../Image/logoteam.png";
import logoCircle from "../../Image/logocircle.png"
import "./styles.css";
export const ActivityIndicator = (props) => {
  return (



    <>
      {/* style={{ maxWidth: "100%", maxHeight: "100%" }}  */}
      {!props?.circle &&
        <div style={{ opacity: 0.7 }} className="container-activity">
          <div className="activity">
            <img className="animation" src={logo} />
          </div>
        </div>

      }
      {props?.circle &&
        <div className="container-activity">
          <div className="activity">
            <svg style={{ maxWidth: "100%", maxHeight: "100%" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#ccc"></stop><stop offset=".3" stop-color="#ccc" stop-opacity=".9"></stop><stop offset=".6" stop-color="#ccc" stop-opacity=".6"></stop><stop offset=".8" stop-color="#ccc" stop-opacity=".3"></stop><stop offset="1" stop-color="#ccc" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="1" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#110A0F" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
            <div className="activity-circle">
              <img style={{ maxWidth: "85%", maxHeight: "100%", borderRadius: 50, opacity: 0.7 }} src={logoCircle} />
            </div>
          </div>
        </div>
      }


    </>

  );
};
