import { Avatar, Col, Drawer, Row } from "antd";
import {
  EnvironmentFilled,
  CalendarFilled,
  ArrowRightOutlined,
  HeartFilled,
  EditFilled    
} from "@ant-design/icons";
import "./styles.css";
import { TimelineView } from "../Timeline/Timeline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTeamById } from "../../servicers/api-team";
import { createImageUrl } from "../../servicers/api-get-img";
import LoadingImage from "../img-loading";

export const ProfilePLayer = (props) => {
  const { onClose, open, data } = props;
  const [dataTeam, setDataTeam] = useState({});
  const navigate = useNavigate();
  const showProfileTeam = (data) => {
    navigate("/profile-team", { state: data });
  };



  return (
    <Drawer
      width={640}
      placement="right"
      closable={true}
      onClose={onClose}
      open={open}
    >
      <div className="container-profile-player">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={9} lg={9} xl={9}>
            <div className="item-profile-player-left">
              <div className="img-profile">
                {data?.avatar == null ? (
                  <Avatar
                    style={{ width: "100%", height: "100%" }}
                    src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`}
                  />
                ) : (
                  // <img
                  //   crossorigin="anonymous"
                  //   src={createImageUrl(data?.avatar)}
                  // />
                  <LoadingImage
                    src={createImageUrl(data?.avatar)}
                    alt={`Image`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                )}
              </div>
              <div className="contact-profile">
                <h3>INFOMATION</h3>
                <p>
                  <span className="icon-contact-profile">
                    <EditFilled />
                  </span>
                  {data?.id}
                </p>
                <p>
                  <span className="icon-contact-profile">
                    <HeartFilled />
                  </span>
                  {data?.gender}
                </p>
                <p>
                  <span className="icon-contact-profile">
                    <CalendarFilled />
                  </span>
                  {data?.age}
                </p>
                <p>
                  <span className="icon-contact-profile">
                    <EnvironmentFilled />
                  </span>
                  {"Viet Nam"}
                </p>
              </div>

              <div className="skill-profile">
                <h3>SKILLS</h3>
                <hr
                  style={{
                    backgroundColor: "#000",
                    height: "2px",
                    border: "none",
                  }}
                />
                <ul style={{ padding: "2px 12px" }}>
                  {data?.category &&
                    Array.isArray(data?.category) &&
                    data?.category?.map((item, index) => {
                      return (
                        <li key={index}>
                          <div>{item?.category} </div>
                          <div style={{fontWeight:700, fontSize:12}}> (Point : {item?.point})</div>
                        </li>
                      );
                    })}
                </ul>
              </div>

              {/* <div className="skill-profile">
                <h3>HOBBIES</h3>
                <hr
                  style={{
                    backgroundColor: "#000",
                    height: "2px",
                    border: "none",
                  }}
                />
                <ul style={{ padding: "2px 12px" }}>
                  <li>{""}</li>
                </ul>
              </div> */}
            </div>
          </Col>
          <Col xs={24} sm={24} md={15} lg={15} xl={15}>
            <div className="item-profile-player-right">
              <div>
                <h1>{data?.name}</h1>
                <span>Athlete</span>
              </div>

              <div className="target-player">
                <h3>ABOUT ME </h3>
                <p>
                  Tôi là {data?.name}, một vận động viên trượt patin chuyên
                  nghiệp với nhiều năm kinh nghiệm trong lĩnh vực thể thao đầy
                  thử thách này. Tôi đã bắt đầu hành trình của mình trong môn
                  thể thao này từ khi còn trẻ, và từ đó, niềm đam mê và sự cống
                  hiến của tôi đã dẫn tôi đến những thành công đáng tự hào trên
                  sân trượt.
                </p>
              </div>

              <div className="achievements-profile">
                <h3>ACHIEVEMENTS</h3>
                <hr
                  style={{
                    backgroundColor: "#000",
                    height: "2px",
                    border: "none",
                    marginBottom: 24,
                  }}
                />
                <TimelineView data={data?.achievements} />
              </div>
              <div className="team-profile">
                <h3>TEAM</h3>
                <hr
                  style={{
                    backgroundColor: "#000",
                    height: "2px",
                    border: "none",
                  }}
                />
                <Row gutter={24}>
                  <Col span={7}>
                    <p>Name :</p>
                    <p>Founding : </p>
                  </Col>
                  <Col span={10}>
                    <p>{data?.team?.name}</p>
                    <p>{data?.team?.createdAt?.slice(0, 10)}</p>
                  </Col>
                  <Col span={7}>
                    <p
                      onClick={() => showProfileTeam(data?.team)}
                      style={{
                        color: "#fff",
                        display: "flex",
                        gap: 5,
                        cursor: "pointer",
                        justifyContent: "center",
                        padding: "4px 0px",
                        borderRadius: 5,
                        backgroundColor: "#000",
                      }}
                    >
                      <span>More</span>
                      <ArrowRightOutlined />
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};
