import "./styles.css"
import logo from "../../Image/translate.png"
import { useState } from "react";

export const HomeAbout = () => {
  const [translate, setTranslate] = useState(false)
  return (
    <div className="about-home">
      <h3>Vietnam Roller JSC</h3>
      {translate &&
        <p>
          Công ty Cổ phần Phát triển Thể thao Việt Nam Roller tự hào là đơn vị tiên phong trong lĩnh vực đào tạo và phát triển bộ môn Roller tại Việt Nam. Thành lập với sứ mệnh nâng cao thể chất và tinh thần cộng đồng thông qua môn thể thao hấp dẫn này, chúng tôi cam kết mang đến cho khách hàng những dịch vụ và sản phẩm tốt nhất
          Tại Việt Nam Roller, chúng tôi có hệ thống trung tâm đào tạo chuyên nghiệp tại thành phố Hồ Chí Minh, Hà Nội và Bình Dương.  Bên cạnh việc đào tạo kỹ năng Patin chuyên nghiệp chúng tôi còn xây dựng một môi trường thân thiện và năng động, nơi mà mọi người, từ trẻ em đến người lớn, đều có thể thỏa sức khám phá và phát triển đam mê của mình. Roller là niềm đam mê của chúng tôi và mục tiêu của chúng tôi là truyền lửa đến khắp cả nước.
          Tự hào là đơn vị đầu tiên có huấn luyện viên người nước ngoài trực tiếp giảng dạy, cùng đội ngũ huấn luyện viên là vận động viên Quốc tế và vân động viên Quốc gia giàu kinh nghiệm và nhiệt huyết luôn đồng hành và hỗ trợ học viên từng bước. Áp dụng mô hình đào tạo chất lượng cao theo giáo trình Singapore, giúp học viên phát triển toàn diện về kỹ năng và thể chất.
          Ngoài ra, chúng tôi tổ chức cũng như tài trợ nhiều sự kiện và các giải đấu Roller đa dạng, tạo cơ hội cho các vận động viên giao lưu, thi đấu, tập huấn các giải trong và ngoài nước. Những sự kiện này không chỉ mang lại niềm vui và hứng khởi mà còn thúc đẩy phong trào thể thao trong cộng đồng Roller, góp phần xây dựng một xã hội khỏe mạnh và đoàn kết, tạo cơ hội cho các vận động viên thể hiện tài năng và kết nối với những người có cùng đam mê
          Công ty Việt Nam Roller còn phân phối một loạt các sản phẩm  giày Patin chất lượng cao, từ giày cơ bản đến chuyên nghiệp, phụ kiện đến trang phục thể thao, đáp ứng mọi nhu cầu của khách hàng. Chúng tôi luôn đặt chất lượng và sự hài lòng của khách hàng lên hàng đầu, với mong muốn mang lại những trải nghiệm tuyệt vời nhất
        </p>
      }

      {!translate &&
        <p>
          Vietnam Roller Sports Development Joint Stock Company is proud to be the first unit in the field of training and developing Roller in Vietnam. Established with the mission of improving the physical and mental health of the community through this attractive sport, we are committed to bringing customers the best services and products. At Vietnam Roller, we have a system of professional training centers in Ho Chi Minh City, Hanoi and Binh Duong. In addition to training professional Roller skills, we also build a friendly and dynamic environment, where everyone, from children to adults, can support their strength to explore and develop their passion. Roller is our passion and our goal is to spread it throughout the country. Proud to be the first unit to have foreign coaches directly teaching, along with a team of coaches who are international athletes and national athletes with rich experience and enthusiasm, always accompanying and supporting students step by step. Applying a high-quality training model according to the Singapore education program, helping students develop comprehensively in terms of skills and physical fitness. In addition, we also organize diverse sponsorships for many events and Roller solutions, creating opportunities for hosting transactions, competitions, and training of domestic and foreign solutions. These events not only bring joy and excitement but also provide sports movements in the Roller community, contributing to building a strong and connected health, creating opportunities for athletes to demonstrate their talents and abilities and connect with people who share the same passion. Vietnam Roller Company distributes a range of high-quality Roller shoes, from basic to professional shoes, accessories to sportswear, meeting all customer requests. We always put the quality and satisfaction of customers first, with the desire to bring the best experiences
        </p>

      }

      <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', width: '100%', marginTop: 24 }}>
        <button
          onClick={() => setTranslate(prev => !prev)}
          style={{ display: 'flex', gap: 5, backgroundColor: "#000", color: "#fff", fontWeight: 900, padding: "2px 12px", borderRadius: 10, alignItems: 'center', width: 100 }}>
          {/* <img style={{ width: "50%", height: 30 }} src={logo} /> */}
          <p style={{ width: "100%" }}>
            {translate ? "Dịch" : "Translate"}

          </p>
        </button>
      </div>



    </div>
  );
};
