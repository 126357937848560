import React, { useEffect, useState } from "react";
import { Space, Table, Tag , message } from "antd";
import { CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import { fakeData } from "../../Data/fake-data-ranking";
import { ProfilePLayer } from "../profile-player";
import { getTeamById } from "../../servicers/api-team";
import { getAthleteById, getTopAthlete } from "../../servicers/api-athlete";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Point",
    dataIndex: "totalPoints",
    key: "totalPoints",
  },
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    render: (text) => <a style={{ color: "#000", fontWeight: 700 }}>{text}</a>,
  },
];

export const HomeTableRanking = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [dataModal, setDataModal] = useState({});
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const getAthleteTop = async (limit) => {
    const response = await getTopAthlete(limit);
    if (response?.data?.EC == 0) {
      const transformed = await Promise.all(
        response?.data?.DT.map(async (item, index) => {
          const team = await getTeamById(item?.team);
          return {
            ...item,
            age: String(calculateAge(item?.age)),
            point: item?.category?.map((cat) => cat?.point).join(", "),
            category: "Total : " + item?.category?.length,
            logoTeam: team?.data?.DT?.logoTeam,
            team: team?.data?.DT?.name,
            rank: index + 1,
          };
        })
      );
      setList(transformed);
    }
  };

  useEffect(() => {
    getAthleteTop(50);
  }, []);

  const handleRowClick = async (record) => {
    console.log("Clicked row:", record); // Log the clicked row data
    const res = await getAthleteById(record.id);
    if (res?.data?.EC == 0) {
      const getDataTeam = await getTeamById(res?.data?.DT?.team);
      if (getDataTeam?.data?.EC == 0) {
        setDataModal({ ...res?.data?.DT, team: getDataTeam?.data?.DT });
        showDrawer();
      }
    } else {
      messageApi.open({
        type: "error",
        content: res?.data?.EM,
      });
    }

    // setDataModal({...record})
    // showDrawer();
  };
  return (
    <div style={{ background: "#fff" }} className="container-home">
      {contextHolder}
      <div style={{ gap: "10px", display: "flex" }}>
        <span style={{ fontWeight: 800 }}>Home</span>
        <CaretRightOutlined />
        <span>Top 50 player</span>
      </div>
      <div style={{ padding: "24px 0" }}></div>
      <Table
        style={{ cursor: "pointer" }}
        dataSource={list}
        responsive="true"
        size="small"
        columns={columns}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
      <ProfilePLayer data={dataModal} open={open} onClose={onClose} />
    </div>
  );
};
