import React, { useState } from "react";
import { Button, Drawer, Space, message } from "antd";
import { CreatePlayer } from "../cms-create-player";
import { deleteAthlete } from "../../servicers/api-athlete";

export const PopUpDataDetail = (props) => {
  const { open, size, onClose, data } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const typeModel = "update";

  const handleDeletePlayer = async () => {
    const req = await deleteAthlete(data.id);
    if (req?.data?.EC == 0) {
      messageApi.open({
        type: "success",
        content: req?.data?.EM,
      });
    } else {
      messageApi.open({
        type: "error",
        content: req?.data?.EM,
      });
    }
    onClose();
  };
  return (
    <>
      {contextHolder}
      <Drawer
        title={`Cập nhật thông tin`}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Huỷ</Button>
            <Button type="primary" danger onClick={handleDeletePlayer}>
              Xoá người dùng
            </Button>
          </Space>
        }
      >
        <CreatePlayer typeModel={typeModel} open={open} onClose={onClose} data={data} />
      </Drawer>
    </>
  );
};
