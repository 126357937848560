import "./styles.css";
import { Row, Col, Modal } from "antd";
import headerContent from "../../Image/headerContent.png";
import React, { useEffect, useState } from "react";
import { InfoPlayer } from "../info-player";
import { getTopAthlete } from "../../servicers/api-athlete";
import LoadingImage from "../img-loading";
import { createImageUrl } from "../../servicers/api-get-img";
import { getTeamById } from "../../servicers/api-team";
import { useDispatch, useSelector } from "react-redux";
import { ActivityIndicator } from "../ActivityIndicator";
import {
  LeftOutlined,
  RightOutlined,
  CaretRightOutlined,
  CaretLeftOutlined,
} from "@ant-design/icons";


export const HeaderHome = () => {
  const dispatch = useDispatch();
  const [renderPlayerTop, setRenderPlayerTop] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModel, setShowModel] = useState(false);
  const [dataInfo, setDataInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const getAthleteTop = async (limit) => {
    const response = await getTopAthlete(limit);
    if (response?.data?.EC == 0) {
      console.log(response?.data?.DT);

      const transformed = await Promise.all(
        response?.data?.DT.map(async (item) => {
          const team = await getTeamById(item?.team);
          return {
            ...item,
            age: String(calculateAge(item?.age)),
            point: item?.category?.map((cat) => cat?.point).join(", "),
            category: item?.category?.map((cat) => cat?.category).join(", "),
            logoTeam: team?.data?.DT?.logoTeam,
            team: team?.data?.DT?.name,
          };
        })
      );
      console.log("check , hehe", transformed);
      setRenderPlayerTop(transformed);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAthleteTop(8);
  }, []);

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, renderPlayerTop.length - 1)
    );
    // console.log("currentIndex", currentIndex)
    if (currentIndex == Math.min(renderPlayerTop.length - 2)) {
      setCurrentIndex(0);
    }
  };

  const hanldShowInfo = (data, ranking) => {
    setDataInfo({ ...data, rank: ranking + 1 });
    setShowModel(true);
  };
  return (
    <>
      <header className="header-home">
        <Row>

          {loading && (
            <Col span={24}>
              <div
                style={{
                  height: "140px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator circle={true} />
              </div>

            </Col>

          )}

          {!loading && (

            <>
              <Col xs={24} sm={8} md={8} lg={4}>
                {/* <p style={{fontWeight : 700 , color:'#fff'}}>Roller skating ranking</p> */}
                <div className="container-header-home" style={{ display: "flex" }}>
                  <div className="title">
                    <p style={{ fontWeight: 900, color: "#fff" }}>Top</p>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="line-header-home"></div>
                    </div>
                    <p style={{ fontWeight: 900, color: "#fff" }}>Player</p>
                  </div>
                  <img src={headerContent} />
                </div>
              </Col>

              <Col xs={24} sm={16} md={16} lg={20}>

                <div style={{ left: 0 }} className="btn-group-header-home">
                  <button
                    className="button-slide"
                    onClick={goToPrev}
                    disabled={currentIndex === 0}
                  >
                    <LeftOutlined style={{ fontSize: 20 }} />
                  </button>
                </div>
                <div style={{ right: 12 }} className="btn-group-header-home">
                  <button
                    className="button-slide"
                    onClick={goToNext}
                    disabled={currentIndex === renderPlayerTop.length - 1}
                  >

                    <RightOutlined style={{ fontSize: 20 }} />
                  </button>
                </div>

                <div className="top-player-slider">
                  <div
                    style={{ transform: `translateX(-${currentIndex * 11}%)` }}
                    className="top-player-list"
                  >
                    {renderPlayerTop.map((item, index) => {
                      return (
                        <div
                          onClick={() => hanldShowInfo(item, index)}
                          key={index}
                          className="top-player-list-item"
                        >
                          <div className="flex-end-item">
                            <a className="number-ranking">{index + 1}</a>
                          </div>
                          {item?.avatar == null && (
                            <LoadingImage
                              src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}
                              style={{
                                width: 100,
                                height: 100,
                                borderTopRightRadius: "70%",
                                borderBottomLeftRadius: "70%",
                                backgroundColor: "#ccc",
                              }}
                            />
                          )}
                          {item?.avatar !== null && (
                            <LoadingImage
                              src={createImageUrl(item?.avatar)}
                              style={{
                                width: 100,
                                height: 100,
                                borderTopRightRadius: "70%",
                                borderBottomLeftRadius: "70%",
                              }}
                            />
                          )}

                          <div className="flex-end-item">
                            <p
                              style={{
                                color: "#000",
                                fontWeight: 700,
                                fontSize: 12,
                              }}
                            >
                              {item?.name}
                            </p>
                            <a style={{ color: "#1dd01d", fontWeight: 700 }}>
                              {item?.totalPoints}
                            </a>
                          </div>

                          <div className="logoTeam">
                            {item?.logoTeam !== null && (
                              <LoadingImage
                                src={createImageUrl(item?.logoTeam)}
                              />
                            )}
                            {item?.logoTeam == null && (
                              <LoadingImage
                                src={`https://t4.ftcdn.net/jpg/04/72/65/73/360_F_472657366_6kV9ztFQ3OkIuBCkjjL8qPmqnuagktXU.jpg`}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </header>
      <Modal
        title="INFOMATION"
        centered
        open={showModel}
        onCancel={() => setShowModel(false)}
        footer={null}
        width="auto"
      >
        <InfoPlayer close={setShowModel} data={dataInfo} />
      </Modal>
    </>
  );
};
